/* eslint-disable max-len */
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import {
  CompleteInspectionModalProps,
  CompleteInspectionModals,
  ServiceLocation,
  completeInspectionModalsList,
} from '../interfaces/Components';
import { InspectionData, InspectionImages } from '../interfaces/UserVehicle';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import { GET_USER_VEHICLE_LATEST_MILEAGE } from '../util/gql';
import AdditionalVehicleInfo from './AdditionalVehicleInfo';
import Loader from './Loader';
import ModalCloseButton from './ModalCloseButton';
import VehicleInspection from './VehicleInspection';
import VehicleInspectionPage2 from './VehicleInspectionPage2';
import InspectionImage from './InspectionImage';
import Subscription from './Subscription';
import InvoiceDiscount from './InvoiceDiscount';
import { SubscriptionType } from '../interfaces/Subscription';

export default function CompleteInspectionModal(
  { workOrderDetails, onClose, onSubmit }: CompleteInspectionModalProps,
) {
  const [
    vin,
    setVin,
  ] = useState<string | undefined>(workOrderDetails?.vin);
  const [mileage, setMileage] = useState<number>();
  const [poNumber, setPO] = useState<string>('');
  const [inspectionData, setInspectionData] = useState<InspectionData>({});
  // eslint-disable-next-line max-len
  const [inspectionImages, setinspectionImages] = useState<InspectionImages>({});
  const [vinLocation, setVinLocation] = useState<string>('');
  const [vehicleLocation, setVehicleLocation] = useState<string>('');
  const [mileageLocation, setMileageLocation] = useState<string>('');
  const [oilCapLocation, setOilCapLocation] = useState<string>('');
  const [oilFilterLocation, setOilFilterLocation] = useState<string>('');
  const [dipstickLocation, setDipstickLocation] = useState<string>('');
  const [oilFilterId, setOilFilterId] = useState<string>('');

  const [checkedFrontLeft, setCheckedFrontLeft] = useState(false);
  const [checkedFrontRight, setCheckedFrontRight] = useState(false);
  const [checkedRearLeft, setCheckedRearLeft] = useState(false);
  const [checkedRearRight, setCheckedRearRight] = useState(false);
  const [cabinFilterId, setCabinFilterId] = useState<string>('');
  const [engineFilterId, setEngineFilterId] = useState<string>('');

  const [brakeFluid, setBrakeFluid] = useState(false);
  const [windshieldFluid, setWindshieldFluid] = useState(false);
  const [leakInspection, setLeakInspection] = useState(false);

  const [tinLocationFrontLeft, setTinLocationFrontLeft] = useState<string>('');
  const [tinLocationFrontRight, setTinLocationFrontRight] = useState<string>('');
  const [tinLocationRearLeft, setTinLocationRearLeft] = useState<string>('');
  const [tinLocationRearRight, setTinLocationRearRight] = useState<string>('');

  const [vehicleLocationFrontLeft, setVehicleLocationFrontLeft] = useState<string>('');
  const [vehicleLocationFrontRight, setVehicleLocationFrontRight] = useState<string>('');
  const [vehicleLocationRearLeft, setVehicleLocationRearLeft] = useState<string>('');
  const [vehicleLocationRearRight, setVehicleLocationRearRight] = useState<string>('');
  const [servicesLocation, setServicesLocation] = useState<ServiceLocation[]>([]);

  const [discountNum, setDiscountNum] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string>('');
  const [totalOriginal, setTotalOriginal] = useState<number>(0);

  const [
    selectedSubscription,
    setSelectedSubscription,
  ] = useState<SubscriptionType | undefined>();

  const setInvoiceDiscountDetails = (type: string, num: number) => {
    setDiscountType(type);
    setDiscountNum(num);
  };

  const [
    activeModal,
    setActiveModal,
  ] = useState<CompleteInspectionModals>('inspection');
  const { data, loading } = useQuery(GET_USER_VEHICLE_LATEST_MILEAGE, {
    variables: { userVehicleId: workOrderDetails?.userVehicleId },
    onCompleted: () => {
      if (data.getLatestMileageByUserVehicleId?.mileage) {
        setMileage(data.getLatestMileageByUserVehicleId.mileage);
      }
    },
    fetchPolicy: 'network-only',
  });

  const resetTotalOriginal = () => {
    let total = 0;
    workOrderDetails?.lineitems.forEach((item) => {
      if (item.discountedPrice && Number.isFinite(item.discountedPrice)) {
        total += item.discountedPrice;
      } else total += item.price;
    });
    setTotalOriginal(total / 100);
  };

  const setInvoicePODetails = (po: string) => {
    setPO(po);
  };

  /* const shouldDisableSubmit = !mileage
    || mileage === 0
    || vin?.trim().length !== 17
    || !vehicleLocationFrontLeft
    || !vehicleLocationFrontRight
    || !vehicleLocationRearLeft
    || !vehicleLocationRearRight; */
  const shouldDisableSubmit = !mileage
  || mileage === 0
  || vin?.trim().length !== 17;

  let isInspectionDataValid;
  if (workOrderDetails && Array.isArray(workOrderDetails.lineitems) && workOrderDetails.lineitems.some((item) => item.name.includes('Tire Rotation'))) {
    isInspectionDataValid = !inspectionData.brakeConditionFrontLeft
    || !inspectionData.brakeConditionFrontRight
    || !inspectionData.brakeConditionRearLeft
    || !inspectionData.brakeConditionRearRight
    || !inspectionData.tireConditionFrontLeft
    || !inspectionData.tireConditionFrontRight
    || !inspectionData.tireConditionRearLeft
    || !inspectionData.tireConditionRearRight;
    /* || !inspectionData.tireIdNumberFrontLeft
    || !inspectionData.tireIdNumberFrontRight
    || !inspectionData.tireIdNumberRearLeft
    || !inspectionData.tireIdNumberRearRight */
  } else {
    isInspectionDataValid = false;
  }

  /* const isAdditionalInspectionDataValid = !inspectionData.tireSize
    || !inspectionData.windshieldsCondition; */
  const isAdditionalInspectionDataValid = !inspectionData.windshieldsCondition;

  /* const isProofOfFixEntered = !oilCapLocation
  || !oilFilterId
  || !servicesLocation; */
  let isProofOfFixEntered = false;
  if (
    workOrderDetails
    && Array.isArray(workOrderDetails.lineitems)
    && workOrderDetails.lineitems.some((item) => ['Basic Oil Change', 'Premium Synthetic', 'European Synthetic'].some((name) => item.name.includes(name)))
  ) {
    isProofOfFixEntered = !dipstickLocation
    || !oilCapLocation;
  }
  const handleSubmit = () => {
    onSubmit(
      vin,
      mileage,
      inspectionData,
      mileageLocation,
      vinLocation,
      vehicleLocation,
      oilCapLocation,
      oilFilterLocation,
      dipstickLocation,
      oilFilterId,
      checkedFrontLeft,
      checkedFrontRight,
      checkedRearLeft,
      checkedRearRight,
      brakeFluid,
      windshieldFluid,
      leakInspection,
      vehicleLocationFrontLeft,
      vehicleLocationFrontRight,
      vehicleLocationRearLeft,
      vehicleLocationRearRight,
      tinLocationFrontLeft,
      tinLocationFrontRight,
      tinLocationRearLeft,
      tinLocationRearRight,
      servicesLocation,
      cabinFilterId,
      engineFilterId,
      discountType,
      discountNum,
      selectedSubscription?.id,
    );
    onClose();
  };

  const handleChangeActiveModal = (
    modalName: CompleteInspectionModals,
  ) => setActiveModal(modalName);

  const handleModalContinue = () => {
    const currentModalIndex = completeInspectionModalsList.indexOf(activeModal);
    const nextModal = completeInspectionModalsList[currentModalIndex + 1];
    if (nextModal === 'invoice') resetTotalOriginal();
    handleChangeActiveModal(nextModal);
  };

  const handleModalBack = () => {
    const currentModalIndex = completeInspectionModalsList.indexOf(activeModal);
    const nextModal = completeInspectionModalsList[currentModalIndex - 1];
    handleChangeActiveModal(nextModal);
  };

  if (loading) {
    return (
      <div className={styles.empty}>
        <Loader />
        Loading
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <ModalCloseButton onClose={onClose} />
      {activeModal === 'inspection' && (
        <VehicleInspection
          inspectionData={inspectionData}
          setInspectionData={setInspectionData}
          checkedFrontLeft={checkedFrontLeft}
          setCheckedFrontLeft={setCheckedFrontLeft}
          checkedFrontRight={checkedFrontRight}
          setCheckedFrontRight={setCheckedFrontRight}
          checkedRearLeft={checkedRearLeft}
          setCheckedRearLeft={setCheckedRearLeft}
          checkedRearRight={checkedRearRight}
          setCheckedRearRight={setCheckedRearRight}
          tinLocationFrontLeft={tinLocationFrontLeft}
          setTinLocationFrontLeft={setTinLocationFrontLeft}
          tinLocationFrontRight={tinLocationFrontRight}
          setTinLocationFrontRight={setTinLocationFrontRight}
          tinLocationRearLeft={tinLocationRearLeft}
          setTinLocationRearLeft={setTinLocationRearLeft}
          tinLocationRearRight={tinLocationRearRight}
          setTinLocationRearRight={setTinLocationRearRight}
          onClose={onClose}
          onClick={handleModalContinue}
          isContinueButtonInactive={isInspectionDataValid}
        />
      )}
      {activeModal === 'inspection2' && (
        <VehicleInspectionPage2
          inspectionData={inspectionData}
          setInspectionData={setInspectionData}
          onClose={handleModalBack}
          onClick={handleModalContinue}
          isContinueButtonInactive={isAdditionalInspectionDataValid}
        />
      )}
      {activeModal === 'inspectionImages' && (
        <InspectionImage
          inspectionImages={inspectionImages}
          setinspectionImages={setinspectionImages}
          brakeFluid={brakeFluid}
          setBrakeFluid={setBrakeFluid}
          windshieldFluid={windshieldFluid}
          setWindshieldFluid={setWindshieldFluid}
          leakInspection={leakInspection}
          setLeakInspection={setLeakInspection}
          oilCapLocation={oilCapLocation}
          setOilCapLocation={setOilCapLocation}
          dipstickLocation={dipstickLocation}
          setDipstickLocation={setDipstickLocation}
          workOrderDetails={workOrderDetails}
          servicesLocation={servicesLocation}
          setServicesLocation={setServicesLocation}
          oilFilterLocation={oilFilterLocation}
          setOilFilterLocation={setOilFilterLocation}
          oilFilterId={oilFilterId}
          setOilFilterId={setOilFilterId}
          onClose={handleModalBack}
          onClick={handleModalContinue}
          isContinueButtonInactive={isProofOfFixEntered}
        />
      )}
      {activeModal === 'additionalInfo'
      && (
        <AdditionalVehicleInfo
          vehicleVin={workOrderDetails?.vin}
          vin={vin}
          setVin={setVin}
          mileage={mileage}
          setMileage={setMileage}
          mileageLocation={mileageLocation}
          setMileageLocation={setMileageLocation}
          vinLocation={vinLocation}
          setVinLocation={setVinLocation}
          vehicleLocationFrontLeft={vehicleLocationFrontLeft}
          setVehicleLocationFrontLeft={setVehicleLocationFrontLeft}
          vehicleLocationFrontRight={vehicleLocationFrontRight}
          setVehicleLocationFrontRight={setVehicleLocationFrontRight}
          vehicleLocationRearLeft={vehicleLocationRearLeft}
          setVehicleLocationRearLeft={setVehicleLocationRearLeft}
          vehicleLocationRearRight={vehicleLocationRearRight}
          setVehicleLocationRearRight={setVehicleLocationRearRight}
          cabinFilterId={cabinFilterId}
          setCabinFilterId={setCabinFilterId}
          engineFilterId={engineFilterId}
          setEngineFilterId={setEngineFilterId}
          onClose={handleModalBack}
          onClick={handleModalContinue}
          isContinueButtonInactive={shouldDisableSubmit}
        />
      )}
      {activeModal === 'subscription' && (
        <Subscription
          onClose={handleModalBack}
          onClick={handleModalContinue}
          setSelectedSubscription={setSelectedSubscription}
          selectedSubscription={selectedSubscription}
          vehicleSubscription={workOrderDetails?.vehicleSubscription}
        />
      )}
      {activeModal === 'invoice'
      && (
        <InvoiceDiscount
          workOrderDetails={workOrderDetails}
          setInvoiceDiscountDetails={setInvoiceDiscountDetails}
          totalOriginal={totalOriginal}
          onBack={handleModalBack}
          onClick={handleSubmit}
          selectedSubscription={selectedSubscription}
        />
      )}
    </div>
  );
}
