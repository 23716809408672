import { useLazyQuery } from '@apollo/client';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import { Invoice, InvoiceProps } from '../interfaces/Invoice';
import { invoiceStatus } from '../lang/en/payment.json';
import styles from '../sass/components/InvoiceDetails.module.scss';
import { api } from '../util/api';
import { ROUTE_FRANCHISE_MANAGER_WORK_ORDERS } from '../util/constants';
import { convertToCurrency } from '../util/currencyFormat';
import { GET_INVOICE } from '../util/gql';
import Button from './Button';
import CompleteModal from './CompleteModal';
import ConfirmationModal from './ConfirmationModal';
import DetailsRow from './DetailsRow';
import ManualPaymentModal from './ManualPaymentInvoiceModal';
import VoidModal from './VoidInvoiceModal';
import RefundPaymentModal from './RefundPaymentModal';
import SendPaymentToTerminal from './SendPaymentToTerminalModal';
import GetCashAppPayment from './GetCashAppQRCodeModal';
import SuccessModal from './SuccessModal';

interface ErrorModalProps {
  message: string,
  title: string
}

export default function InvoiceDetails() {
  const navigate = useNavigate();

  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceProps>();

  const [manualPaymentModal, setManualPaymentModal] = useState<boolean>(false);
  const [voidModal, setVoidModal] = useState<boolean>(false);
  const [refundPaymentModal, setRefundPaymentModal] = useState<boolean>(false);

  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [
    cashAppPaymentModal,
    setCashAppPaymentModal,
  ] = useState<boolean>(false);

  const [
    manualPaymentErrorModal,
    setManualPaymentErrorModal,
  ] = useState<boolean>(false);

  const [
    terminalPaymentModal,
    setTerminalPaymentModal,
  ] = useState<boolean>(false);

  const [
    sendToTerminalSuccessModal,
    setSendToTerminalSuccessModal,
  ] = useState<boolean>(false);

  const [
    cashAppErrorModal,
    setcashAppErrorModal,
  ] = useState<boolean>(false);

  const [
    cashAppPaymentSuccessModal,
    setCashAppPaymentSuccessModal,
  ] = useState<boolean>(false);
  const [
    terminalPaymentErrorModal,
    setTerminalPaymentErrorModal,
  ] = useState<boolean>(false);

  const [
    downloadInvoiceErrorModal,
    setDownloadInvoiceErrorModal,
  ] = useState<boolean>(false);

  const [
    voidErrorModal,
    setVoidErrorModal,
  ] = useState<boolean>(false);

  const [
    refundPaymentErrorModal,
    setRefundPaymentErrorModal,
  ] = useState<boolean>(false);

  const { id } = useParams();

  const invoiceId = useAppSelector((state) => state.invoice.id) || Number(id);
  const approvedPayments = invoiceDetails?.payment?.filter(
    (payment) => payment.status === 'APPROVED',
  ) || [];
  const currentReader = useRef<string>('');

  const backToWorkOrder = (): void => navigate(
    `${ROUTE_FRANCHISE_MANAGER_WORK_ORDERS}/${invoiceDetails?.workOrderId}`,
  );

  const getSelectedReader = (readerId: string) => {
    currentReader.current = readerId;
  };

  const downloadInvoice = async () => {
    try {
      const response = await api.get(
        `account/franchisee/invoices/${invoiceId}`, {
          responseType: 'arraybuffer',
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');
      document.body.appendChild(link);
      link.click();
    } catch {
      setDownloadInvoiceErrorModal(true);
    }
  };

  const [getInvoice, { loading, refetch }] = useLazyQuery(GET_INVOICE, {
    onCompleted: (data) => {
      const invoice: Invoice = data?.getInvoiceById;
      let tax;
      if (invoice.discountSubTotal === 0) {
        tax = 0;
      } else {
        tax = (invoice.discountSubTotal || invoice.subTotal) * invoice.tax;
      }
      setInvoiceDetails({
        id: invoice.id,
        userEmail: invoice.user.email,
        userId: invoice.user.id,
        vendorId: invoice.user.vendorId,
        address: `${invoice.address.street}, ${invoice.address.city}`,
        countryCode: invoice.address.countryCode,
        contact: invoice.contact?.name,
        franchise: invoice.franchise.name,
        franchiseId: invoice.franchise.id,
        statusLabel: invoiceStatus[
          invoice.status as keyof typeof invoiceStatus
        ],
        tax: convertToCurrency(((
          tax
        ))),
        payment: invoice.payment,
        billedAmountFormatted: convertToCurrency(invoice.billedAmount),
        remainingAmount: invoice.remainingAmount,
        remainingAmountFormatted: convertToCurrency(invoice.remainingAmount),
        workOrderId: invoice.workOrder.id,
        billedAmount: invoice.billedAmount,
        discountSubTotal: invoice.discountSubTotal,
        subTotal: invoice.subTotal,
        subTotalFormatted: convertToCurrency(invoice.subTotal),
        discountAmount: invoice.discountAmount,
        ...invoice.discountAmount && invoice.discountAmount > 0 && {
          discountAmountFormatted: convertToCurrency(invoice.discountAmount),
        },
      });
    },
    onError: backToWorkOrder,
    fetchPolicy: 'network-only',
  });

  const handleCloseErrorModal = () => {
    setManualPaymentErrorModal(false);
    setTerminalPaymentErrorModal(false);
    setVoidErrorModal(false);
    backToWorkOrder();
  };

  const handleCloseSuccessModal = () => {
    setSendToTerminalSuccessModal(false);
    refetch();
    backToWorkOrder();
  };

  const handleCloseCashAppSuccessModal = () => {
    setCashAppPaymentSuccessModal(false);
    refetch();
    backToWorkOrder();
  };

  useEffect(() => {
    if (!invoiceId) backToWorkOrder();
    getInvoice({ variables: { invoiceId } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  const Loading = () => (
    <p className={styles.loading}>Loading...</p>
  );

  const PayInvoiceErrorModal = ({ message, title }: ErrorModalProps) => (
    <CompleteModal
      onClose={handleCloseErrorModal}
      currentReader={null}
      invoiceId={invoiceId}
      open={manualPaymentErrorModal || terminalPaymentErrorModal}
      message={message}
      title={title}
    />
  );

  const DownloadInvoiceErrorModal = ({ message, title }: ErrorModalProps) => (
    <ConfirmationModal
      onClose={handleCloseErrorModal}
      message={message}
      title={title}
      open={downloadInvoiceErrorModal}
    />
  );

  const VoidErrorModal = ({ message, title }: ErrorModalProps) => (
    <ConfirmationModal
      onClose={handleCloseErrorModal}
      message={message}
      title={title}
      open={voidErrorModal}
    />
  );

  const RefundPaymentErrorModal = ({ message, title }: ErrorModalProps) => (
    <ConfirmationModal
      onClose={handleCloseErrorModal}
      message={message}
      title={title}
      open={refundPaymentErrorModal}
    />
  );

  const VoidInvoice = () => (
    <VoidModal
      invoiceDetails={invoiceDetails}
      onClose={() => setVoidModal(false)}
      voidInvoiceModal={voidModal}
      setErrorModal={setVoidErrorModal}
    />
  );

  const RefundPayment = () => (
    <RefundPaymentModal
      invoiceDetails={invoiceDetails}
      onClose={() => setRefundPaymentModal(false)}
      refundPaymentModal={refundPaymentModal}
      setErrorModal={setRefundPaymentErrorModal}
    />
  );

  const SendToTerminalSuccessModal = () => (
    <CompleteModal
      onClose={handleCloseSuccessModal}
      open={sendToTerminalSuccessModal}
      currentReader={currentReader.current}
      invoiceId={invoiceId}
      title="Terminal Payment"
      // eslint-disable-next-line max-len
      message="You're almost done! Open your credit card terminal to finish processing this payment."
    />
  );
  const CashAppSuccessModal = () => (
    <SuccessModal
      onClose={handleCloseSuccessModal}
      open={cashAppPaymentSuccessModal}
      invoiceId={invoiceId}
      title="Payment Successful"
      // eslint-disable-next-line max-len
      message="Your payment with Cash App was successfull"
    />
  );

  const ManualInvoicePayment = () => (
    <ManualPaymentModal
      invoiceDetails={invoiceDetails}
      onClose={() => setManualPaymentModal(false)}
      payInvoiceModal={manualPaymentModal}
      setErrorModal={setManualPaymentErrorModal}
    />
  );

  const TerminalInvoicePayment = () => (
    <SendPaymentToTerminal
      invoiceDetails={invoiceDetails}
      onClose={() => setTerminalPaymentModal(false)}
      open={terminalPaymentModal}
      setErrorModal={() => setManualPaymentErrorModal(true)}
      setSuccessModal={() => setSendToTerminalSuccessModal(true)}
      setCurrentReader={getSelectedReader}
    />
  );
  const CashAppInvoicePayment = () => (
    <GetCashAppPayment
      invoiceDetails={invoiceDetails}
      onClose={() => setCashAppPaymentModal(false)}
      open={cashAppPaymentModal}
      setErrorModal={() => setcashAppErrorModal(true)}
      setSuccessModal={() => setCashAppPaymentSuccessModal(true)}
      isPaid={isPaid}
      setIsPaid={setIsPaid}
    />
  );

  const InvoiceDetailsRow = () => (
    <div className={styles.invoiceDetailsContainer}>
      <div className={styles.backButtonContainer}>
        <div className={styles.backButtonContent}>
          <Button
            className={cx(styles.caret)}
            type="button"
            onClick={backToWorkOrder}
            variant="icon"
          >
            <Arrow />
          </Button>
          <h5>
            Invoice
            {' '}
            {invoiceDetails?.id}
          </h5>
        </div>
        <div className={styles.payInvoiceButtonContainer}>
          {invoiceDetails?.statusLabel !== invoiceStatus.VOIDED
            && invoiceDetails?.statusLabel !== invoiceStatus.PAID
            && invoiceDetails?.statusLabel !== invoiceStatus.REFUNDED
            && invoiceDetails?.statusLabel !== invoiceStatus.PARTIALLY_REFUNDED
            && invoiceDetails?.statusLabel !== invoiceStatus.PARTIALLY_PAID
            && invoiceDetails?.contact
            && (
            <Button
              onClick={() => setVoidModal(true)}
              className={styles.downloadButton}
            >
              Void Invoice
            </Button>
            )}
          <Button
            onClick={downloadInvoice}
            className={styles.downloadButton}
          >
            Download Invoice
          </Button>
        </div>
      </div>
      <DetailsRow
        title="Work Order"
        text={invoiceDetails?.workOrderId?.toString()}
      />
      <DetailsRow
        title="Customer"
        text={invoiceDetails?.userEmail}
      />
      <DetailsRow
        title="Address"
        text={invoiceDetails?.address}
      />
      <DetailsRow
        title="Contact"
        text={invoiceDetails?.contact
          ? invoiceDetails?.contact
          : 'Contact was deleted'}
      />
      <DetailsRow
        title="Status"
        text={invoiceDetails?.statusLabel}
      />
      <DetailsRow
        title="Subtotal"
        text={invoiceDetails?.subTotalFormatted}
      />
      {invoiceDetails?.discountAmountFormatted && (
        <DetailsRow
          title="Discount"
          text={invoiceDetails?.discountAmountFormatted}
        />
      )}
      <DetailsRow
        title="Tax"
        text={invoiceDetails?.tax}
      />
      <DetailsRow
        title="Billed Amount"
        text={invoiceDetails?.billedAmountFormatted}
      />
      { invoiceDetails?.statusLabel === invoiceStatus.PARTIALLY_PAID && (
        <DetailsRow
          title="Remaining Amount"
          text={invoiceDetails?.remainingAmountFormatted}
        />
      )}
      {invoiceDetails?.statusLabel === invoiceStatus.OPEN && (
        <div className={styles.payInvoiceButtonContainer}>
          <Button
            onClick={() => setTerminalPaymentModal(true)}
            variant="primary"
          >
            Terminal Payment
          </Button>
          <Button
            onClick={() => setManualPaymentModal(true)}
            variant="primary"
            className="completeWorkOrder"
          >
            Manual Payment
          </Button>
          {invoiceDetails?.countryCode === 'US'
          && (
          <Button
            onClick={() => setCashAppPaymentModal(true)}
            variant="primary"
            className="completeWorkOrder"
          >
            Cash App
          </Button>
          )}
        </div>
      )}

      {invoiceDetails?.statusLabel === invoiceStatus.PARTIALLY_PAID && (
      <div className={styles.payInvoiceButtonContainer}>
        <Button
          onClick={() => setManualPaymentModal(true)}
          variant="primary"
          className="completeWorkOrder"
        >
          Manual Payment
        </Button>
      </div>
      )}

      {invoiceDetails?.statusLabel === invoiceStatus.PAID
       && approvedPayments.length === 1
      && (
        <div className={styles.payInvoiceButtonContainer}>
          <Button
            onClick={() => setRefundPaymentModal(true)}
            variant="primary"
          >
            Refund Payment
          </Button>
        </div>
      )}
      {voidModal && <VoidInvoice />}
      {refundPaymentModal && <RefundPayment />}
      {manualPaymentModal && <ManualInvoicePayment />}
      {terminalPaymentModal && <TerminalInvoicePayment />}
      {cashAppPaymentModal && <CashAppInvoicePayment />}
      {manualPaymentErrorModal && (
        <PayInvoiceErrorModal
          message="Error: Could not pay invoice, try again later."
          title="Pay Invoice"
        />
      )}
      {cashAppErrorModal && (
        <PayInvoiceErrorModal
          message="Error: Could not pay invoice, try again later."
          title="Pay Invoice"
        />
      )}
      {terminalPaymentErrorModal && (
        <PayInvoiceErrorModal
          message="Error processing your payment,
            try again or process a manual payment"
          title="Terminal Payment"
        />
      )}
      {downloadInvoiceErrorModal && (
        <DownloadInvoiceErrorModal
          message="Error downloading the invoice, please try again."
          title="Invoice Download"
        />
      )}
      {voidErrorModal && (
        <VoidErrorModal
          message="Error: Could not void invoice, try again later."
          title="Void Invoice"
        />
      )}
      {refundPaymentErrorModal && (
        <RefundPaymentErrorModal
          message="Error: Could not refund payment, try again later."
          title="Refund Payment"
        />
      )}
      <SendToTerminalSuccessModal />
      <CashAppSuccessModal />
    </div>
  );

  return (
    <div>
      {loading ? <Loading /> : <InvoiceDetailsRow />}
    </div>
  );
}
