import { useMutation } from '@apollo/client';
import {
  FormEvent, useState, useEffect,
} from 'react';
import { InvoiceProps } from '../interfaces/Invoice';
import styles from '../sass/components/PayInvoiceModal.module.scss';
import {
  REFUND_PAYMENT,
  GET_INVOICE,
} from '../util/gql';
import Button from './Button';
import Modal from './Modal';
import Field from './Field';
import TextInput from './TextInput';
import ModalCloseButton from './ModalCloseButton';

interface RefundModalProps {
  invoiceDetails?: InvoiceProps;
  refundPaymentModal: boolean;
  onClose: () => void;
  setErrorModal: (status: boolean) => void;
}

export default function RefundPaymentModal(
  {
    invoiceDetails,
    refundPaymentModal,
    onClose,
    setErrorModal,
  }: RefundModalProps,
) {
  const [amount, setAmount] = useState<string>('0');
  // eslint-disable-next-line max-len
  const [refundButtonDisabled, setRefundButtonDisabled] = useState<boolean>(true);
  const [initialAmount, setInitialAmount] = useState<number>(0);

  useEffect(() => {
    if (invoiceDetails) {
      setAmount((invoiceDetails?.billedAmount / 100).toFixed(2));
      // eslint-disable-next-line max-len
      setInitialAmount((invoiceDetails?.billedAmount / 100));
    }
  }, [invoiceDetails]);

  const invoiceId = invoiceDetails?.id;

  const handleInputChange = (e: FormEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    setAmount(value);
  };

  const [refundPayment] = useMutation(REFUND_PAYMENT, {
    variables: { invoiceId, amount: parseFloat(amount) * 100 },
    onCompleted: () => {
      onClose();
    },
    onError: () => {
      onClose();
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const parsedAmount = parseFloat(amount);
    if (parsedAmount > initialAmount || parsedAmount <= 0 || !amount) {
      setRefundButtonDisabled(true);
    } else {
      setRefundButtonDisabled(false);
    }
  }, [amount, initialAmount]);

  const handleSubmit = (): void => {
    refundPayment({
      refetchQueries: () => [{
        query: GET_INVOICE,
        variables: { invoiceId: invoiceDetails?.id },
      }],
    });
  };

  return (
    <Modal
      open={refundPaymentModal}
      onClose={onClose}
      disableBackdropClick
    >
      <div className={styles.modal}>
        <ModalCloseButton onClose={onClose} />
        <div className={styles.body}>
          <h4>Refund Payment</h4>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          >
            <Field id="amount" label="Amount to Refund">
              <TextInput
                type="number"
                name="amount"
                value={amount}
                onChange={handleInputChange}
                placeholder="Enter the amount you want to pay"
                isRequired
              />
            </Field>
            <div className={styles.buttonsContainer}>
              <Button
                className={styles.button}
                onClick={onClose}
                variant="tertiary"
              >
                Cancel
              </Button>
              <Button
                className={styles.button}
                type="submit"
                inactive={refundButtonDisabled}
              >
                Refund
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
